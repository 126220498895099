<template>
  <div>
    <vs-sidebar click-not-close parent="#post-app" :hidden-background="true" class="full-vs-sidebar post-view-sidebar items-no-padding" v-model="isUpdateActive" position-right id="post-update">

      <div class="flex flex-wrap items-center justify-between md:px-8 px-6 py-2 d-theme-dark-bg post-header bg-primary">
        <div class="flex">
          <feather-icon :icon="$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'" @click="closeUpdateView" class="cursor-pointer mr-4" svg-classes="text-white w-6 h-6"></feather-icon>
          <h5 class="text-white pt-1">{{ Object.entries(this.data).length === 0 ? "New" : "View" }} Pages</h5>
        </div>

        <div class="ml-10 flex items-center">
          <div v-if="Object.entries(this.data).length != 0">
            <div class="flex mr-4" v-if="filter != 'trash'">
              <vs-switch class="mr-2 pt-1" color="dark" v-model="disabled" v-can="'E'"/>
              <vs-button class="mr-4" color="danger" size="small" icon-pack="feather" icon="icon-trash" @click="hapusPagesConfirm()" v-can="'D'">Trash</vs-button>
            </div>
            <div class="flex mr-4" v-else>
              <vs-button class="mr-4" color="warning" size="small" icon-pack="feather" icon="icon-refresh-ccw" @click="restorePagesConfirm()" v-can="'D'">Restore</vs-button>
            </div>
          </div>
          <vs-button v-if="filter != 'trash'" class="" size="small" :disabled="!isFormValid" @click="submitData" color="success" icon-pack="feather" icon="icon-send">Terbitkan</vs-button>
        </div>
      </div>
      <div class="px-0 sm:pb-6 h-full post-area-loading vx-row">
        
        <component class="post-content-scroll-area vx-col w-3/4" :settings="settings" :is="scrollbarTag" key="1">
          <div class="vx-row mb-5">
            <div class="vx-col md:w-1/4 mt-5">
              <span class="text-sm">Tanggal</span>
              <datepicker placeholder="Select Date" v-model="dataTanggal" :disabled="disabled"></datepicker>
            </div>
            <div class="vx-col md:w-3/4 mt-5">
              <vs-input label="Title" v-model="dataTitle" class="w-full" name="item-title" v-validate="'required'" :disabled="disabled"/>
            </div>
          </div>
          <vue-editor id="editor" :editorOptions="editorSettings" useCustomImageHandler @image-added="handleImageAdded" v-model="dataContent" class="" style="height: 500px;" :disabled="disabled"></vue-editor>
        </component>
        
        <component class="scroll-area-post-content vx-col w-1/4" :settings="settings" :is="scrollbarTag" key="2">
            <vx-card title="Gambar">
              <template v-if="Attachment">
                
                <div class="img-container w-64" v-if="upImg">
                  <img :src="getImage('/media/'+Attachment)" alt="" class="responsive">
                </div>

                <div v-else class="img-container w-64">
                  <img :src="Attachment" alt="" class="responsive">
                </div>
                
                <div class="modify-img flex justify-between mt-5 mb-5">
                  <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
                  <vs-button class="w-full" @click="$refs.updateImgInput.click();upImg = false" :disabled="disabled">Update Image</vs-button>
                </div>
              </template>

              <div class="upload-img" v-if="!Attachment">
                <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
                <vs-button @click="$refs.uploadImgInput.click(); upImg = false" class="w-full" :disabled="disabled">Upload Image</vs-button>
              </div>
            </vx-card>
            <!--
            <vx-card title="Kutipan" class="mt-5">
              <vs-textarea v-model="dataKutipan" :disabled="disabled"/>
            </vx-card>
            <vx-card title="Diskusi" class="mt-5">
              <vs-radio v-model="dataKomentar" vs-value="true" :disabled="disabled">Komentar</vs-radio>
            </vx-card>
            <vx-card title="Kategori" class="mt-5">
              <ul>
                <li class="mb-2" v-for="kategori in postKategoris" :key="kategori.id">
                  <input type="checkbox" :key="kategori.id" name="Kategori" :value="kategori.id" v-model="dataKategori" :disabled="disabled" /> {{ kategori.name }}
                </li>
              </ul>
            </vx-card>
            <vx-card title="Tag" class="mt-5">
              <ul class="">
                <li class="mb-2" v-for="tag in postTags" :key="tag.id">
                  <input type="checkbox" :key="tag.id" name="Tag" :value="tag.id" v-model="dataTag" :disabled="disabled" /> {{ tag.name }}
                </li>
              </ul>
            </vx-card>
            -->
        </component>
        
      </div>

      <vs-prompt
        color="danger"
        title="Konfirmasi"
        @accept="hapusPagesAccept"
        :active.sync="hapusPages">
        <div class="con-exemple-prompt">
          Yakin ingin menghapus post {{ dataTitle }}
        </div>
      </vs-prompt>

      <vs-prompt
        color="warning"
        title="Konfirmasi"
        @accept="restorePagesAccept"
        :active.sync="restorePages">
        <div class="con-exemple-prompt">
          Yakin ingin me-restore post {{ dataTitle }}
        </div>
      </vs-prompt>
    </vs-sidebar>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import VuePerfectScrollbar  from 'vue-perfect-scrollbar'
import { mapGetters } from 'vuex'
import themeConfig from "@/../themeConfig.js"
import { CurrencyDirective } from 'vue-currency-input'
import { VueEditor, Quill } from 'vue2-editor'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'
import pdf from 'vue-pdf'
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);


export default{
  props: {
    isUpdateActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    Datepicker,
    VuePerfectScrollbar,
    VueEditor,
    pdf
  },
  data () {
    return {
      // Set
        settings: {
          maxScrollbarLength: 60,
          wheelSpeed: 0.50
        },
        disabled: false,
        editorSettings: {
          modules: {
            imageDrop: true,
            imageResize: {}
          }
        },

      // Init
        dataParent: 0,
        dataPagessID: 0,
        dataTanggal: new Date(),
        dataTitle: null,
        dataContent: null,
        dataKutipan: null,
        dataKomentar: false,
        //dataKategori: [],
        dataTag: [],
        
        Attachment : null,
        upImg: true,
      
      hapusPages: false,
      restorePages: false
    }
  },
  directives: {
    currency: CurrencyDirective
  },
  watch: {
    isUpdateActive (value) {
      if (value) {
        if (Object.entries(this.data).length === 0) {
          this.disabled = false
          if (themeConfig.mobileMode === true) {
            navigator.geolocation.getCurrentPosition(this.loadLatLng  , this.mapError, { enableHighAccuracy: true });
          }
          this.initValues()
          this.$validator.reset()
        } else {
          this.disabled = true
          const { ID, posts_ID, post_content, post_title, post_excerpt, comment_status, post_parent, tanggal, thumbnail } = JSON.parse(JSON.stringify(this.data))
          //tags, kategori, 

          if (post_parent != 0) {
            this.dataParent = post_parent
          } else {
            this.dataParent = ID
          }
          this.dataPagessID = posts_ID
          this.dataTanggal = tanggal
          this.dataContent = post_content
          this.dataTitle = post_title
          this.dataKutipan = post_excerpt
          this.dataKomentar = comment_status

          //this.dataTag = tags
          /*
          let a = []
          for (const el of kategori) { a.push(el.id) }
          this.dataKategori = a

          let b = []
          for (const el of tags) { b.push(el.id) }
          this.dataTag = b
          */
          
          this.Attachment = thumbnail
        }
      } else { return }
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    ...mapGetters({
      user : 'auth/user',
    }),
    isFormValid () { 
      return !this.errors.any() && !this.disabled && this.dataTitle && this.dataContent
    },
    mobile () {
      return themeConfig.mobileMode
    },
    filter () {
      return this.$route.params.filter
    },
    postKategoris () {
      return this.$store.state.pages.postKategoris
    },
    postTags () {
      return this.$store.state.pages.postTags
    },
  },
  created () {
  },
  methods: {
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      this.$vs.loading()
      let config = {
        headers: {
          'Authorization': 'Bearer ' + this.user.api_token,
        },
      }
 
      var formData = new FormData();
      formData.append("image", file);
      
      this.axios.post('/insert-image', formData, config)
      .then((response) => {
        this.$vs.loading.close()
        let {data} = response
        let url = this.getImage('/media/'+data.message); // Get url from response
        Editor.insertEmbed(cursorLocation, "image", url);
        resetUploader();
      })
      .catch((error) => {
        this.$vs.notify({
          title: error.message,
          text  : 'Tidak dapat mengupload',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        this.$vs.loading.close()
      })
    },
    submitData () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()

          let formData = new FormData()
          formData.set('post_content', this.dataContent)
          formData.set('post_title', this.dataTitle)
          formData.set('post_kutipan', this.dataKutipan)
          formData.set('post_parent', this.dataParent)
          formData.set('posts_id', this.dataPagessID)
          formData.set('post_type', 'post')
          formData.set('comment_status', this.dataKomentar)
          formData.set('post_password', 'null')

          //formData.set('post_kategori', JSON.stringify(this.dataKategori))
          //formData.set('post_tag', JSON.stringify(this.dataTag))

          formData.set('attachment_upload', this.upImg)
          if (this.upImg === false) { 
            formData.append('attachment_file', this.Attachment)
          } else {
            formData.set('attachment_file', this.Attachment)
          }

          let meta = {
            tanggal : this.formatDate(this.dataTanggal)
          }
          formData.set('meta', JSON.stringify(meta))
          
          if (this.dataParent === 0) {
            formData.set('post_type', 'pages')
            formData.set('post_status', 'publish')
            this.$store.dispatch('pages/addUpdate', formData)
            .then((response) => {
              this.$vs.loading.close()
              this.$vs.notify({
                title: 'Berhasil',
                color  : 'success',
                text  : response.message,
              })
              this.initValues()
              this.closeUpdateView()
              this.initValues()
            })
            .catch(error => {
              this.$vs.loading.close()
              let {data} = error.response
              this.$vs.notify({
                title: 'Error',
                text: data.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            })
          } else {
            formData.set('post_type', 'revision')
            formData.set('post_status', 'inherit')
            this.$store.dispatch('pages/editUpdate', formData)
            .then((response) => {
              this.$vs.loading.close()
              this.$vs.notify({
                title: 'Berhasil',
                color  : 'success',
                text  : response.message,
              })
              this.initValues()
              this.closeUpdateView()
              this.initValues()
            })
            .catch(error => {
              this.$vs.loading.close()
              let {data} = error.response
              this.$vs.notify({
                title: 'Error',
                text: data.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            })
          }
        }
      })
    },
    hapusPagesConfirm () {
      this.hapusPages = true
    },
    hapusPagesAccept () {
      if (Object.entries(this.data).length > 0) {
        this.$vs.loading()
        this.$store.dispatch('pages/removePost', this.data)
        .then((response) => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Berhasil',
            color  : 'success',
            text  : response.message,
          })
          this.initValues()
          this.closeUpdateView()
          this.initValues()
        })
      }
      this.hapusPages = false
    },
    restorePagesConfirm () {
      this.restorePages = true
    },
    restorePagesAccept () {
      if (Object.entries(this.data).length > 0) {
        this.$vs.loading()
        this.$store.dispatch('pages/restorePost', this.data)
        .then((response) => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Berhasil',
            color  : 'success',
            text  : response.message,
          })
          this.initValues()
          this.closeUpdateView()
          this.initValues()
        })
      }
      this.restorePages = false
    },
    initValues() {
      this.dataParent = 0
      this.dataPagessID = 0
      this.dataTitle = null
      this.dataContent = null
      this.dataKutipan = null
      this.dataKomentar = false
      //this.dataKategori = []
      this.dataTag = []
      
      this.Attachment = null
      this.upImg = true

      this.dataTanggal = new Date()
      this.disabled = false 
      this.hapusPages = false
      this.restorePages = false
    },
    updateCurrImg (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.Attachment = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    // MOBILE
    cameraTakePicture() { 
      if (themeConfig.mobileMode === true) {
        navigator.camera.getPicture(this.cameraSuccess, this.cameraFail,this.opti);
      }
    },
    cameraFail(message) { 
      this.$vs.notify({
        color : 'danger',
        text  : message,
      })
    }, 
    cameraSuccess (imageData) {
      this.Attachment = "data:image/jpeg;base64," + imageData;
    },
    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
    },
    closeUpdateView() {
      this.initValues()
      this.$emit('closeUpdate')
      this.initValues()
    }
  }
}
</script>
<style lang="scss" scoped>
.post-view-sidebar {
  ::v-deep .vs-sidebar {
    z-index: 41000;
    max-width: 100%;
    margin-left: 0;
  }
  .vx-card {
    box-shadow: none;
    border: 1px solid #ccc;
    border-radius: 0;
  }
  .utama {
    width: 75%;
  }
  .atribut {
    width: 25%;
  }
}
</style>