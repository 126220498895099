
<template>
    <div class="post__filters-container">
      
        <div class="px-6 pt-6">
            <router-link tag="span" class="flex cursor-pointer" :class="{'text-primary': postFilter == 'all'}" :to="`${baseUrl}/filter/all`">
                <feather-icon icon="LayersIcon" :svgClasses="[{'text-primary stroke-current': postFilter == 'all'}, 'h-6 w-6']"></feather-icon>
                <span class="ml-3">All</span>
            </router-link>
        </div>
        
        <!--
        <vs-divider></vs-divider>
        <div class="px-6 py-4">
            <div class="flex items-center flex-wrap justify-between">
                <div class="flex items-center">
                  <h5>Kategori</h5>
                </div>
                <div class="flex">
                    <feather-icon icon="PlusIcon" class="cursor-pointer ml-5" svg-classes="h-6 w-6" @click="kategoriAddOpen" />
                </div>
            </div>
            <template v-for="kategori in postKategoris">
                <router-link tag="span" class="flex mt-6 cursor-pointer" :to="`${baseUrl}/kategori/${kategori.slug}`" :key="kategori.slug">
                    <span class="" :class="{'text-primary': postFilter == kategori.slug}">{{ kategori.name }}</span>
                </router-link>
            </template>
        </div>

        <vs-divider></vs-divider>

        <div class="px-6 py-4">
          <div class="flex items-center flex-wrap justify-between">
              <div class="flex items-center">
                <h5>Tag</h5>
              </div>
              <div class="flex">
                  <feather-icon icon="PlusIcon" class="cursor-pointer ml-5" svg-classes="h-6 w-6" @click="activePromptTags = true" />
              </div>
          </div>
          <div class="post__lables-list">
              <router-link tag="span" class="post__label flex items-center mt-6 cursor-pointer" v-for="(tag, index) in postTags" :key="index" :to="`${baseUrl}/tag/${tag.slug}`">
                  <div class="ml-1 h-3 w-3 rounded-full mr-4" :class="'border-2 border-solid border-' + tag.color" />
                  <span class="" :class="{'text-primary': postFilter == tag.slug}">{{ tag.name }}</span>
              </router-link>
          </div>
        </div>
        <vs-prompt
        title="Add Tag"
        accept-text= "Submit"
        button-cancel = "border"
        @cancel="clearTag"
        @accept="submitTag"
        @close="clearTag"
        :is-valid="validateTag"
        :active.sync="activePromptTags">
          <div>
            <form>

              <div class="vx-row">
                <div class="vx-col w-full">
                  <vs-input name="title" class="w-full mb-4 mt-5" placeholder="Title" v-model="dataTag" />
                  <vs-select
                    class="w-full"
                    label="Color"
                    v-model="dataColor"
                    >
                      <vs-select-item value="pilih" text="Pilih"/>
                      <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in dataColorx"/>
                  </vs-select>
                </div>
              </div>

            </form>
          </div>
        </vs-prompt>
        -->

        <vs-divider></vs-divider>
        
        <div class="px-6 py-4">
          <router-link tag="span" class="flex cursor-pointer" :class="{'text-primary': postFilter == 'trash'}" :to="`${baseUrl}/filter/trash`">
              <feather-icon icon="TrashIcon" :svgClasses="[{'text-primary stroke-current': postFilter == 'trash'}, 'h-6 w-6']"></feather-icon>
              <span class="text-lg ml-3">Trash</span>
          </router-link>
        </div>
    </div>
</template>
  
<script>

export default{
  components: {
  },
  props: {
    postFilter: {
      type: String,
      required: true
    },
    dateMulai: {
      type: String,
    },
    dateSampai: {
      type: String,
    },
    Search: {
      type: String,
    }
  },
  data () {
    return {
      enableCross: false,
      tooltipMerge: false,
      formatter: value => `￥${value}`,

      activePromptTags: false,
      dataTag: null,
      dataColor: 'pilih',
      dataColorx: ['primary', 'success', 'danger', 'warning', 'dark']
    }
  },
  computed: {
    validateTag () {
      return !this.errors.any() && this.dataTag && this.dataColor != 'pilih'
    },
    postTags () {
      return this.$store.state.pages.postTags
    },
    postKategoris () {
      return this.$store.state.pages.postKategoris
    },
    taxonomy () {
      return 'tag'
    },
    /*
    postFilter () {
      return this.$route.params.filter
    },
    */
    baseUrl () {
      const path = this.$route.path
      const paths = path.slice(0, path.lastIndexOf('/'))
      return paths.slice(0, paths.lastIndexOf('/'))
    }
  },
  methods: {
    clearTag () {
      this.dataTag = null
      this.dataColor = 'pilih'
    },
    submitTag () {
      this.$vs.loading()
      const obj = {
        name: this.dataTag,
        term_group: 0,
        parent: 0,

        meta : {
          color: this.dataColor
        },

        taxonomy: 'tag'
      }

      this.$store.dispatch('pages/addTag', obj)
      .then((response) => {
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Berhasil',
          color  : 'success',
          text  : response.message,
        })
      })
      .catch(error => {
        this.$vs.loading.close()
        let {data} = error.response
        this.$vs.notify({
          title: 'Error',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
      this.clearTag()
      this.activePromptTags = false
    },
    kategoriAddOpen() {
      this.$emit('openKategoriAdd')
    }
  },
  created () {
  }
}
</script>
